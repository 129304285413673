// Import the functions you need from the SDKs you need
import React from "react";
import "@fontsource/lato"; // Defaults to weight 400 with all styles included. REGULAR
import "@fontsource/lato/900.css"; // Weight 900 with all styles included. BLACK
import "@fontsource/lato/700.css"; // Weight 700 with all styles included. BOLD
import "@fontsource/lato/300.css"; // Weight 700 with all styles included. LIGHT
var _paq = (window._paq = window._paq || []);
// OR require user cookie consent before storing any cookies
_paq.push(["requireConsent"]);

_paq.push(["trackPageView"]);
_paq.push(["enableLinkTracking"]);

(function () {
  var u = "//stats.wissenschaft-im-dialog.de/";
  _paq.push(["setTrackerUrl", u + "matomo.php"]);
  _paq.push(["setSiteId", "8"]);
  var d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.async = true;
  g.src = u + "matomo.js";
  s.parentNode.insertBefore(g, s);
})();

// (function () {
//   var u = "//www.wissenschaft-im-dialog.de/matomo/";
//   _paq.push(["setTrackerUrl", u + "matomo.php"]);
//   _paq.push(["setSiteId", "26"]);
//   var d = document,
//     g = d.createElement("script"),
//     s = d.getElementsByTagName("script")[0];
//   g.type = "text/javascript";
//   g.async = true;
//   g.src = u + "matomo.js";
//   s.parentNode.insertBefore(g, s);
// })();

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <meta
      key="viewport"
      name="viewport"
      content="width=device-width, initial-scale=1.0"
    />,
  ]);
};
